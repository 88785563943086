import * as React from "react"
import {
  Flex, Box, Text, Breadcrumb, BreadcrumbLink, BreadcrumbItem, HStack, VStack, RadioGroup, Radio, useToast, useDisclosure,
  Center, Heading, Input, Avatar, Spacer, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,
  FormControl, FormLabel, Button, Modal, Thead, Tr, Th, Tbody, Td, Checkbox, Table, ModalFooter, Tabs,
  TabList, TabPanels, Tab, TabPanel, Image } from "@chakra-ui/react"
import { Link, useParams, useRouteMatch, useHistory } from "react-router-dom"
import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { OnlyAdmins, User } from "../../types/UserTypes";
import { assignPromoCodeUser, changeStatus, getUser, promoCodesAvailable } from "../../api/UserService";
import { getPictureAdmin } from "../../api/ProfileService";
import { PromoCode } from "../../types/PromoCodeTypes";
import { EntityListResponse } from "../../types/ApiTypes";
import { requestForgotPassword } from "../../api/SignInService";
import { ChangePasswordIcon, ChangeStatusIcon, PromoCodesIcon } from "../../utils/icons";
import { useSessionContext } from "../../context/AuthContext";
import { exportUserSummary } from "../../api/UserExportService";
import { UserDataExportAds, UserDataExportPromoCodes } from "../../types/UserSummaryTypes";
import { applyUsaPhoneMasking } from "../../utils/masking";
import CCSICon from "../../assets/CCSIcon.svg";
import CHAdeMO from "../../assets/CHAdeMO.svg";
import J1772 from "../../assets/J1772.svg";
import { AgeGroup, AgeGroupDescriptions } from '../../enums/UserAgeGroupEnum';
import { toCustomTitleCase } from "../../utils/stringUtils";

export function UserDetails() {
  const toast = useToast();
  let { username } = useParams();
  let match = useRouteMatch();
  let history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [currentUser] = useSessionContext();

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [vehicle, setVehicle] = useState(false);
  const [vehicleBrand, setVehicleBrand] = useState("");
  const [vehicleModel, setVehicleModel] = useState("");
  const [vehicleYear, setVehicleYear] = useState("");
  const [vehiclePlugType, setVehiclePlugType] = useState("");
  const [phone, setPhone] = useState("");
  const [ageGroup, setAgeGroup] = useState("");
  const [gender, setGender] = useState("");
  const [picUrl, setPicUrl] = useState("");
  const [status, setStatus] = useState("INACTIVE");
  const [modalMode, setModalMode] = useState("");

  const [newStatus, setNewStatus] = useState("INACTIVE");
  const [emailToSend, setEmailToSend] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [numberAdsServed, setNumberAdsServed] = useState(0);
  const [numberAdsCompleted, setNumberAdsCompleted] = useState(0);
  const [preferredAdCategories, setPreferredAdCategories] = useState("");

  const [promoCodesReceived, setPromoCodesReceived] = useState(0);
  const [promoCodesUsed, setPromoCodesUsed] = useState(0);
  const [promoCodesUsedInL2, setPromoCodesUsedInL2] = useState(0);
  const [promoCodesUsedInL3, setPromoCodesUsedInL3] = useState(0);
  const [promoCodesExpiredBeforeUse, setPromoCodesExpiredBeforeUse] = useState(0);
  const [totalMoneySaved, setTotalMoneySaved] = useState(0.0);

  const [signUpCompleted, setSignUpCompleted] = useState(false);
  const [questionnaireStarted, setQuestionnaireStarted] = useState(false);
  const [questionnaireCompleted, setQuestionnaireCompleted] = useState(false);

  let initial: PromoCode[] = []
  const [promosList, setPromosList] = React.useState(initial)
  const [promoCode, setPromoCode] = React.useState("")

  const moneyFormat = (val: number) => `$` + val
  const percentageFormat = (val: number) => `-` + val + `%`

  const getIcon = (iconName: string) => {
    if (iconName.toLowerCase() === "chademo") {
      return <Image boxSize="24px" src = { CHAdeMO } alt = "CHAdeMO" />;
    } else if (iconName.toLowerCase() === "ccs") {
      return <Image boxSize="24px" src = { CCSICon } alt = "CCS" />;
    }  else {
      return <Image boxSize="24px" src = { J1772 } alt = "J1772" />;
    }
  };

  const getStatus = () => {
    if (status === "SUSPENDED") {
      return <Center p={2} fontSize="sm" fontWeight="bold" borderRadius="10px" maxW="120px" borderWidth={2} borderColor="#EB5757" color="#EB5757">SUSPENDED</Center>;
    } else if (status === "INACTIVE") {
      return <Center p={2} fontSize="sm" fontWeight="bold" borderRadius="10px" maxW="120px" borderWidth={2} borderColor="#76777A" color="#76777A">INACTIVE</Center>;
    } else {
      return <Center p={2} fontSize="sm" fontWeight="bold" borderRadius="10px" maxW="120px" borderWidth={2} borderColor="primary.500" color="primary.500">ACTIVE</Center>;
    }
  };

  const getModalContent = () => {
    if (modalMode === "PROMO") {
      return <ModalContent minW="550px">
        <ModalHeader color="gostation.500">Send Promo Code</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {promosList.length > 0 ?
            (<Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th />
                  <Th color="gray">PROMO CODE</Th>
                  <Th color="gray">VALUE</Th>
                  <Th color="gray">TYPE</Th>
                </Tr>
              </Thead>
              <Tbody>
                {promosList && promosList.map((promo: PromoCode, i: number) => {
                  return (
                    <Tr key={i}>
                      <Td><Checkbox onChange={(e) => selectPromo(e.target.checked, promo)} /></Td>
                      <Td>{promo.code}</Td>
                      <Td>{promo.valueType === "MONEY" ? moneyFormat(promo.value as number) : percentageFormat(promo.value as number)}</Td>
                      <Td>{promo.type}</Td>
                    </Tr>)
                })}
              </Tbody>
            </Table>)
            : (<Center ml={4} mr={4} minH={200} borderWidth={1} borderStyle="dashed" color="gray" fontSize="xl">
              <Text>No Valid Promo Codes Available</Text>
            </Center>)}
        </ModalBody>
        <ModalFooter>
          <Spacer />
          <Button colorScheme="gostation" onClick={assignPromo}>SAVE</Button>
        </ModalFooter>
      </ModalContent>
    } else if (modalMode === "STATUS") {
      return <ModalContent minW="280px">
        <ModalHeader color="gostation.500">Change Status</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <RadioGroup onChange={setNewStatus} value={newStatus}>
            <VStack alignItems="start" spacing={4} pl={4}>
              <Radio value="ACTIVE" size="lg">
                <Center p={2} ml={2} fontSize="sm" fontWeight="bold" borderRadius="10px" maxW="120px" borderWidth={2} borderColor="primary.500" color="primary.500">ACTIVE</Center>
              </Radio>
              <Radio value="SUSPENDED" size="lg">
                <Center p={2} ml={2} fontSize="sm" fontWeight="bold" borderRadius="10px" maxW="120px" borderWidth={2} borderColor="#EB5757" color="#EB5757">SUSPENDED</Center>
              </Radio>
              <Radio value="INACTIVE" size="lg">
                <Center p={2} ml={2} fontSize="sm" fontWeight="bold" borderRadius="10px" maxW="120px" borderWidth={2} borderColor="#76777A" color="#76777A">INACTIVE</Center>
              </Radio>
            </VStack>
          </RadioGroup>
        </ModalBody>
        <ModalFooter>
          <Spacer />
          <Button colorScheme="gostation" onClick={changeUserStatus}>SAVE</Button>
        </ModalFooter>
      </ModalContent>
    } else if (modalMode === "PASSWORD") {
      return <ModalContent minW="370px">
        <ModalHeader color="gostation.500">Send New Password</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="email" w="315px">
            <FormLabel fontSize="xs" color="gray">Email</FormLabel>
            <Input isRequired bg="#F9F9F9" placeholder="email" value={emailToSend} onChange={(e) => setEmailToSend(e.target.value)} size="md" />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Spacer />
          <Button colorScheme="gostation" onClick={requestForgotPasswordEmail}>SEND</Button>
        </ModalFooter>
      </ModalContent>
    }
  };

  const assignPromo = () => {
    let params = {
      code: promoCode,
      username: username
    }
    assignPromoCodeUser(params).then((response: AxiosResponse) => {
      if ([200, 201].includes(response.status)) {
        setPromosList([]);
        setPromoCode("");
        setModalMode("");
        onClose()
        setRefresh(!refresh);
      } else {
        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
      }
    }).catch((error: any) => {
      toast({
        title: "Failed to add the selected promo code to the user",
        position: "bottom",
        description: JSON.stringify(error?.response?.data?.apierror ? error?.response?.data?.apierror?.message : error?.message),
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    })
  };

  const changeUserStatus = () => {
    let params = {
      username: username,
      status: newStatus
    }
    changeStatus(params).then((response: AxiosResponse) => {
      if ([200, 201].includes(response.status)) {
        setModalMode("");
        onClose()
        setRefresh(!refresh);
      } else {
        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
      }
    }).catch((error: any) => {
      toast({
        title: "Failed to change the user status",
        position: "bottom",
        description: JSON.stringify(error?.response?.data?.apierror ? error?.response?.data?.apierror?.message : error?.message),
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    })
  };

  const requestForgotPasswordEmail = () => {
    requestForgotPassword(email).then((response: AxiosResponse) => {
      if ([200, 201].includes(response.status)) {
        setModalMode("");
        onClose()
        setRefresh(!refresh);
      } else {
        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
      }
    }).catch((error: any) => {
      toast({
        title: "Failed to send a forgot password email to the user",
        position: "bottom",
        description: JSON.stringify(error?.response?.data?.apierror ? error?.response?.data?.apierror?.message : error?.message),
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    })
  };

  const openPromoModal = () => {
    setModalMode("PROMO")
    getUserPromoCodesAvailable()
    onOpen()
  };

  const openStatusModal = () => {
    setModalMode("STATUS")
    setNewStatus(status)
    onOpen()
  };

  const openPasswordModal = () => {
    setModalMode("PASSWORD")
    setEmailToSend(email)
    onOpen()
  };

  const getUserPromoCodesAvailable = () => {
    let params = {
      username: username
    }
    promoCodesAvailable(params).then((response: AxiosResponse) => {
      if ([200, 201].includes(response.status)) {
        let entityResponse: EntityListResponse<PromoCode> = response.data
        setPromosList(entityResponse.data);
      } else {
        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
      }
    }).catch((error: any) => {
      toast({
        title: "Failed to get user's promo codes available from backend",
        position: "bottom",
        description: JSON.stringify(error.message),
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    })
  };

  const selectPromo = (checked: boolean, promo: PromoCode) => {
    if (checked) {
      setPromoCode(promo.code as string)
    } else {
      setPromoCode("")
    }
  };

  useEffect(() => {
    if (username) {
      let params = {
        username: username
      }
      getUser(params).then((response: AxiosResponse) => {
        if ([200, 201].includes(response.status)) {
          let current: User = response.data;
          setEmail(current.email)
          if (current?.profile?.firstName) {
            setFirstName(current?.profile?.firstName)
          }
          const currentVehicle = current?.profile?.vehicles[0];
          if (currentVehicle) {
            setVehicle(currentVehicle);
            if (currentVehicle.brand) {
              setVehicleBrand(currentVehicle.brand);
            }
            if (currentVehicle.model) {
              setVehicleModel(currentVehicle.model);
            }
            if (currentVehicle.year) {
              setVehicleYear(currentVehicle.year);
            }
            if (currentVehicle.plugType) {
              setVehiclePlugType(currentVehicle.plugType);
            }
          }
          if (current?.profile?.lastName) {
            setLastName(current?.profile?.lastName)
          }
          if (current?.profile?.phone) {
            setPhone(applyUsaPhoneMasking(current?.profile?.phone))
          }
          if (current?.profile?.pictureName) {
            let pictureParams = {
              pictureName: current?.profile?.pictureName
            }
            getPictureAdmin(pictureParams).then((response: AxiosResponse) => {
              let picUrl = response.data
              setPicUrl(picUrl);
            });
          }
          if (!current.enabled || current.accountExpired || current.accountLocked) {
            setStatus("SUSPENDED")
          } else if (!current.validated) {
            setStatus("INACTIVE")
          } else {
            setStatus("ACTIVE")
          }
          if (current?.profile?.ageGroup) {
            setAgeGroup(AgeGroupDescriptions[current?.profile?.ageGroup as AgeGroup])
          }
          if (current?.profile?.gender) {
            setGender(toCustomTitleCase(current?.profile?.gender))
          }
        } else {
          return Promise.reject(response.data.apierror.message);
        }
      }).catch((error: any) => {
        toast({
          title: "Failed to get the User data from backend",
          position: "bottom",
          description: JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message),
          status: "error",
          duration: 5000,
          isClosable: true,
        })
        history.push('/users');
      })

      exportUserSummary(params).then((response: AxiosResponse) => {
        if ([200, 201].includes(response.status)) {
          let userAds: UserDataExportAds = response.data.userAds;
          let userPromoCodes: UserDataExportPromoCodes = response.data.userPromoCodes;
          let signUpCompleted: boolean = response.data.signUpCompleted;
          let questionnaireStarted: boolean = response.data.questionnaireStarted;
          let questionnaireCompleted: boolean = response.data.questionnaireCompleted;

          if (userAds?.numberAdsServed) {
            setNumberAdsServed(userAds?.numberAdsServed);
          }
          if (userAds?.numberAdsCompleted) {
            setNumberAdsCompleted(userAds?.numberAdsCompleted);
          }
          if (userAds?.preferredAdCategories) {
            setPreferredAdCategories(userAds?.preferredAdCategories);
          }

          if (userPromoCodes?.promoCodesReceived) {
            setPromoCodesReceived(userPromoCodes?.promoCodesReceived);
          }
          if (userPromoCodes?.promoCodesUsed) {
            setPromoCodesUsed(userPromoCodes?.promoCodesUsed);
          }
          if (userPromoCodes?.promoCodesUsedInL2) {
            setPromoCodesUsedInL2(userPromoCodes?.promoCodesUsedInL2);
          }
          if (userPromoCodes?.promoCodesUsedInL3) {
            setPromoCodesUsedInL3(userPromoCodes?.promoCodesUsedInL3);
          }
          if (userPromoCodes?.promoCodesExpiredBeforeUse) {
            setPromoCodesExpiredBeforeUse(userPromoCodes?.promoCodesExpiredBeforeUse);
          }
          if (userPromoCodes?.totalMoneySaved) {
            setTotalMoneySaved(userPromoCodes?.totalMoneySaved);
          }

          if (signUpCompleted) {
            setSignUpCompleted(signUpCompleted);
          }

          if (questionnaireStarted) {
            setQuestionnaireStarted(questionnaireStarted);
          }
          if (questionnaireCompleted) {
            setQuestionnaireCompleted(questionnaireCompleted);
          }
        } else {
          const error = (response.data && response.data.message) || response.statusText;
          return Promise.reject(error);
        }
      }).catch((error: any) => {
        toast({
          title: "Failed to get users export summary data",
          position: "bottom",
          description: JSON.stringify(error?.response?.data?.apierror ? error?.response?.data?.apierror?.message : error?.message),
          status: "error",
          duration: 5000,
          isClosable: true,
        })
      })
    }
  }, [history, username, toast, refresh]);

  return (
    <Box w="100%" h="90%" bg="background.secondary">
      <Flex w="100%" h="10%" pl={6} alignItems="center">
        <Breadcrumb color="gostation.500" fontWeight="bold" fontSize="lg">
          <BreadcrumbItem color="gostation.100">
            <BreadcrumbLink as={Link} to="/users">
              <Text>Users</Text>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem color="gostation.500" isCurrentPage>
            <BreadcrumbLink as={Link} to={match.url}>
              {username ? <Text>User Profile</Text> : <Text>New User</Text>}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <Flex w="96%" h="90%" minH="500px" ml={6} mr={6}>
        <Tabs variant='enclosed'>
          <TabList>
            <Tab pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">User</Tab>
            <Tab pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">Ads</Tab>
            <Tab pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">Promocode</Tab>
            <Tab pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">Profile Details</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <HStack w="96%" h="90%" minH="500px" spacing={4} alignItems="flex-start">
                <VStack minW="960px" spacing={4}>
                  <Box bg="white" minW="960px" minH="200px" pl={4} pr={4} alignItems="left">
                    <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">User Information</Heading>
                    <HStack spacing={6} pb={6} pt={6}>
                      <FormControl id="username" w="315px">
                        <FormLabel fontSize="xs" color="gray">Username</FormLabel>
                        <Input isRequired isDisabled={username} bg="#F9F9F9" placeholder="username" value={username} size="md" />
                      </FormControl>
                      <FormControl id="password" w="315px">
                        <FormLabel fontSize="xs" color="gray">Password</FormLabel>
                        <Input isRequired isDisabled={username} bg="#F9F9F9" placeholder="password" value={"********"} size="md" />
                      </FormControl>
                      <Spacer />
                      <FormControl id="status" w="220px">
                        <FormLabel fontSize="xs" color="gray">Status</FormLabel>
                        {getStatus()}
                      </FormControl>
                    </HStack>
                  </Box>
                  <Box bg="white" minW="960px" minH="200px" pl={4} pr={4} alignItems="left">
                    <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">Personal Information</Heading>
                    <HStack spacing={6} pb={6} pt={6}>
                      <VStack spacing={4}>
                        <FormControl id="firstName" w="315px">
                          <FormLabel fontSize="xs" color="gray">First Name</FormLabel>
                          <Input isRequired isDisabled={username} bg="#F9F9F9" placeholder="first name" value={firstName} size="md" />
                        </FormControl>
                        <FormControl id="email" w="315px">
                          <FormLabel fontSize="xs" color="gray">Email</FormLabel>
                          <Input isRequired isDisabled={username} bg="#F9F9F9" placeholder="email" value={email} size="md" />
                        </FormControl>
                        <FormControl id="ageGroup" w="315px">
                          <FormLabel fontSize="xs" color="gray">Age Group</FormLabel>
                          <Input isRequired isDisabled={username} bg="#F9F9F9" placeholder="age group" value={ageGroup} size="md" />
                        </FormControl>
                      </VStack>
                      <VStack spacing={4}>
                        <FormControl id="lastName" w="315px">
                          <FormLabel fontSize="xs" color="gray">Last Name</FormLabel>
                          <Input isRequired isDisabled={username} bg="#F9F9F9" placeholder="last name" value={lastName} size="md" />
                        </FormControl>
                        <FormControl id="phone" w="315px">
                          <FormLabel fontSize="xs" color="gray">Phone Number</FormLabel>
                          <Input isRequired isDisabled={username} bg="#F9F9F9" placeholder="phone number" value={phone} size="md" />
                        </FormControl>
                        <FormControl id="gender" w="315px">
                          <FormLabel fontSize="xs" color="gray">Gender</FormLabel>
                          <Input isRequired isDisabled={username} bg="#F9F9F9" placeholder="gender" value={gender} size="md" />
                        </FormControl>
                      </VStack>
                      <Spacer />
                      <FormControl id="picture" w="220px">
                        <FormLabel fontSize="xs" color="gray" pl="20px">Profile Picture</FormLabel>
                        <Avatar size="2xl" alignSelf="center" name={username} src={picUrl} />
                      </FormControl>
                    </HStack>
                  </Box>
                  <Box bg="white" minW="960px" minH="150px" pl={4} pr={4} alignItems="left">
                    <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">Vehicle</Heading>
                    {vehicle ? (
                      <HStack spacing={6} pb={6} pt={6}>
                        <VStack spacing={4} align="start">
                          <FormControl id="make" w="145px">
                            <FormLabel fontSize="xs" color="gray">Make</FormLabel>
                            <Input isRequired isDisabled={vehicle} bg="#F9F9F9" placeholder="make" value={vehicleBrand} size="md" />
                          </FormControl>
                        </VStack>
                        <VStack spacing={4} align="start">
                          <FormControl id="model" w="145px">
                            <FormLabel fontSize="xs" color="gray">Model</FormLabel>
                            <Input isRequired isDisabled={vehicle} bg="#F9F9F9" placeholder="year" value={vehicleYear} size="md" />
                          </FormControl>
                        </VStack>
                        <VStack spacing={4} align="start">
                          <FormControl id="year" w="315px">
                            <FormLabel fontSize="xs" color="gray">Year</FormLabel>
                            <Input isRequired isDisabled={vehicle} bg="#F9F9F9" placeholder="model" value={vehicleModel} size="md" />
                          </FormControl>
                        </VStack>
                        <VStack spacing={3} align="start">
                          <FormControl id="plugType" w="200px">
                            <Flex direction="column" align="center" justify="center">
                              <FormLabel fontSize="xs" color="gray">Plug Type</FormLabel>
                              {getIcon(vehiclePlugType)}
                            </Flex>
                          </FormControl>
                        </VStack>
                      </HStack>
                    ) : (
                      <HStack spacing={6} pb={6} pt={6}>
                        <VStack spacing={4} align="start">
                          <FormControl id="noVehicle" w="400px">
                            <Input isRequired isDisabled={true} bg="#F9F9F9" placeholder="noVehicle" value="This user has not selected a vehicle yet" size="md" />
                          </FormControl>
                        </VStack>
                      </HStack>
                    )}
                  </Box>
                </VStack>
                <VStack minW="228px" bg="white" pl={4} pr={4} pb={4} alignItems="left">
                  <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">Actions</Heading>
                  {(OnlyAdmins.some(role => currentUser.userRoles.includes(role))) && (<HStack onClick={openStatusModal} _hover={{ cursor: "pointer" }}>
                    <ChangeStatusIcon color="gostation.200" boxSize="24px" />
                    <Text fontSize="xs" color="gostation.300">Change Status</Text>
                  </HStack>)}
                  {(OnlyAdmins.some(role => currentUser.userRoles.includes(role))) && (<HStack onClick={openPasswordModal} _hover={{ cursor: "pointer" }}>
                    <ChangePasswordIcon color="gostation.200" boxSize="24px" />
                    <Text fontSize="xs" color="gostation.300">Send new Password</Text>
                  </HStack>)}
                  {(OnlyAdmins.some(role => currentUser.userRoles.includes(role))) && (<HStack onClick={openPromoModal} _hover={{ cursor: "pointer" }}>
                    <PromoCodesIcon color="gostation.200" boxSize="24px" />
                    <Text fontSize="xs" color="gostation.300">Send Promo Code</Text>
                  </HStack>)}
                </VStack>
                <Modal isCentered isOpen={isOpen} onClose={onClose} motionPreset="none">
                  <ModalOverlay />
                  {getModalContent()}
                </Modal>
              </HStack>
            </TabPanel>

            <TabPanel>
              <Box bg="white" minW="960px" minH="325px" pl={4} pr={4} alignItems="left">
                <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">User Ads</Heading>
                <HStack spacing={6} pb={6} pt={6}>
                  <VStack spacing={4}>
                    <FormControl id="ads" w="315px">
                      <FormLabel fontSize="xs" color="gray">Ads Served</FormLabel>
                      <Input isRequired isDisabled={true} bg="#F9F9F9" placeholder="ads served" value={numberAdsServed} size="md" />
                    </FormControl>
                    <FormControl id="adsCompleted" w="315px">
                      <FormLabel fontSize="xs" color="gray">Ads Completed</FormLabel>
                      <Input isRequired isDisabled={true} bg="#F9F9F9" placeholder="ads completed" value={numberAdsCompleted} size="md" />
                    </FormControl>
                    <FormControl id="adCategories" w="315px">
                      <FormLabel fontSize="xs" color="gray">Ad Categories</FormLabel>
                      <Input isRequired isDisabled={true} bg="#F9F9F9" placeholder="ad categories" value={preferredAdCategories} size="md" />
                    </FormControl>
                  </VStack>
                  <Spacer />
                </HStack>
              </Box>
            </TabPanel>

            <TabPanel>
            <Box bg="white" minW="960px" minH="325px" pl={4} pr={4} alignItems="left">
                <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">User Promo Codes</Heading>
                <HStack spacing={6} pb={6} pt={6}>
                  <VStack spacing={4}>
                    <FormControl id="promoCodes" w="315px">
                      <FormLabel fontSize="xs" color="gray">Promo Codes Received</FormLabel>
                      <Input isRequired isDisabled={true} bg="#F9F9F9" placeholder="promo codes received" value={promoCodesReceived} size="md" />
                    </FormControl>
                    <FormControl id="adsCompleted" w="315px">
                      <FormLabel fontSize="xs" color="gray">Promo Codes Used</FormLabel>
                      <Input isRequired isDisabled={true} bg="#F9F9F9" placeholder="promo codes used" value={promoCodesUsed} size="md" />
                    </FormControl>
                    <FormControl id="adsCompleted" w="315px">
                      <FormLabel fontSize="xs" color="gray">Promo Codes Used in L2</FormLabel>
                      <Input isRequired isDisabled={true} bg="#F9F9F9" placeholder="promo codes used in l2" value={promoCodesUsedInL2} size="md" />
                    </FormControl>
                  </VStack>
                  <VStack spacing={4}>
                    <FormControl id="adsCompleted" w="315px">
                      <FormLabel fontSize="xs" color="gray">Promo Codes Used in L3</FormLabel>
                      <Input isRequired isDisabled={true} bg="#F9F9F9" placeholder="promo codes used in l3" value={promoCodesUsedInL3} size="md" />
                    </FormControl>
                    <FormControl id="adsCompleted" w="315px">
                      <FormLabel fontSize="xs" color="gray">Promo Codes Expired Before Use</FormLabel>
                      <Input isRequired isDisabled={true} bg="#F9F9F9" placeholder="promo codes expired before use" value={promoCodesExpiredBeforeUse} size="md" />
                    </FormControl>
                    <FormControl id="adCategories" w="315px">
                      <FormLabel fontSize="xs" color="gray">Total Money Saved</FormLabel>
                      <Input isRequired isDisabled={true} bg="#F9F9F9" placeholder="total money saved" value={totalMoneySaved} size="md" />
                    </FormControl>
                  </VStack>
                  <Spacer />
                </HStack>
              </Box>
            </TabPanel>

            <TabPanel>
              <Box bg="white" minW="960px" minH="120px" pl={4} pr={4} alignItems="left">
                <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">User Sign Up Status</Heading>
                <HStack spacing={6} pb={6} pt={6}>
                  <VStack align="baseline" spacing={3}>
                    <Checkbox isDisabled={true} isChecked={signUpCompleted ? true : false}>Sign Up Completed</Checkbox>
                  </VStack>
                  <Spacer />
                </HStack>
              </Box>

              <Box bg="white" minW="960px" minH="155px" mt={4} pl={4} pr={4} alignItems="left">
                <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">User Questionnaire</Heading>
                <HStack spacing={6} pb={6} pt={6}>
                  <VStack align="baseline" spacing={3}>
                    <Checkbox isDisabled={true} isChecked={questionnaireStarted ? true : false}>Questionnaire Started</Checkbox>
                    <Checkbox isDisabled={true} isChecked={questionnaireCompleted ? true : false}>Questionnaire Completed</Checkbox>
                  </VStack>
                  <Spacer />
                </HStack>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Box>
  );
}
