import * as React from "react"
import {
  useToast,
  Flex, Box, Input, Button, Text, Breadcrumb, BreadcrumbLink, BreadcrumbItem, Table, Thead, Tr, Th, Tbody, Td,
  InputRightElement, InputGroup, Stack, Spacer, Icon, Menu, MenuButton, MenuList, MenuItem, IconButton, Image,
} from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { useEffect } from "react";
import { getVehicleList } from "../../api/VehicleService";
import { Vehicle } from "../../types/VehicleTypes";
import { EntityListResponse } from "../../types/ApiTypes";
import { BiFilter } from "react-icons/bi";
import { AxiosResponse } from "axios";
import CCSICon from "../../assets/CCSIcon.svg"
import CHAdeMO from "../../assets/CHAdeMO.svg"
import J1772 from "../../assets/J1772.svg"
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  changeSize,
  initialDescription,
  isNextPage,
  isPrevPage,
  nextPage,
  prevPage
} from "../../utils/pagination";
import Loader from "../../utils/Loading";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../store/index";
import { setLoading } from '../../actions/loadingActions';

export function Vehicles() {
  const toast = useToast();

  let initialVehicles: Vehicle[] = []

  const [vehiclesList, setVehiclesList] = React.useState(initialVehicles)
  const [pageRequest, setPageRequest] = React.useState(initialDescription)
  const [totalHits, setTotalHits] = React.useState(0)
  const dispatch = useDispatch()
  const isLoading = useSelector((state: RootState) => state.loading.isLoading)

  const getIcon = (iconName: string) => {
    if (iconName.toLowerCase() === "chademo") {
      return <Image boxSize="24px" src = { CHAdeMO } alt = "CHAdeMO" />;
    } else if (iconName.toLowerCase() === "ccs") {
      return <Image boxSize="24px" src = { CCSICon } alt = "CCS" />;
    } else {
      return <Image boxSize="24px" src = { J1772 } alt = "J1772" />;
    }
  };

  useEffect(() => {
    let params = {
      page: pageRequest ? pageRequest.page : 0,
      size: pageRequest ? pageRequest.pageSize : 50
    };

    dispatch(setLoading(true));

    try {
      getVehicleList(params).then((response: AxiosResponse) => {
        if ([200, 201].includes(response.status)) {
          let entityResponse: EntityListResponse<Vehicle> = response.data
          setTotalHits(entityResponse.responseDescription.totalHits)
          setVehiclesList(entityResponse.data);
        } else {
          const error = (response.data && response.data.message) || response.statusText;
          return Promise.reject(error);
        }
      }).catch((error: any) => {
        toast({
          title: "Failed to get Vehicles from backend",
          position: "bottom",
          description: JSON.stringify(error.message),
          status: "error",
          duration: 5000,
          isClosable: true,
        })
      })
      .finally(() => {
        dispatch(setLoading(false));
});
    } catch (error) {
      toast({
        title: "Failed to get Vehicles from backend",
        position: "bottom",
        description: JSON.stringify(error.message),
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  }, [pageRequest, toast, dispatch]);

  return (
    <Box w="100%" h="90%" bg="background.secondary">
      <Flex w="100%" h="10%" pl={6} alignItems="center">
        <Breadcrumb color="gostation.500" fontWeight="bold" fontSize="lg">
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/vehicles">
              <Text>Vehicles</Text>
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <Flex h="8%" ml={6} pl={4} pt={2} pb={2} mr={6} pr={4} alignItems="center" bg="white">
        <InputGroup size="sm" w="50%" >
          <Input
            type="search"
            name="search"
            placeholder="Search vehicles"
          />
          <InputRightElement width="2.5rem">
            <Icon as={BiFilter}/>
          </InputRightElement>
        </InputGroup>
        <Button as={Link} to="/vehicles/makeModel" ml="auto" colorScheme="primary" variant="solid" size="sm">MANAGE MAKES & MODELS</Button>
      </Flex>
      <Box h="6%" ml={6} pl={4} mr={6} pr={4} borderBottomWidth="1px">
        <Stack direction="row" spacing={1} pt={3}>
          <Menu>
            <MenuButton as={Button} size="xs" rightIcon={<ChevronDownIcon />}>
              Vehicles/page: {pageRequest.pageSize}
            </MenuButton>
            <MenuList>
              <MenuItem value={10} onClick={(e) => {changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest)}}>10</MenuItem>
              <MenuItem value={50} onClick={(e) => {changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest)}}>50</MenuItem>
              <MenuItem value={100} onClick={(e) => {changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest)}}>100</MenuItem>
              <MenuItem value={9999} onClick={(e) => {changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest)}}>9999</MenuItem>
            </MenuList>
          </Menu>
          <Spacer/>
          <IconButton
            variant="outline"
            aria-label="Previous page"
            isDisabled={!isPrevPage(pageRequest, totalHits)}
            boxSize={6}
            icon={<ChevronLeftIcon />}
            onClick={() => {prevPage(pageRequest, setPageRequest)}}
          />
          <Text pl={1} fontSize="sm">
            {(pageRequest.pageSize * pageRequest.page)}-{Math.min(pageRequest.pageSize, totalHits)} of {totalHits}
          </Text>
          <IconButton
            variant="outline"
            isDisabled={!isNextPage(pageRequest, totalHits)}
            aria-label="Next page"
            boxSize={6}
            icon={<ChevronRightIcon />}
            onClick={() => {nextPage(pageRequest, setPageRequest)}}
          />
        </Stack>
      </Box>
      {isLoading ? (
            < Loader />
        ) : (
            <>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th color="gostation.500">MAKE</Th>
            <Th color="gostation.500">MODEL</Th>
            <Th color="gostation.500">YEAR</Th>
            <Th color="gostation.500">PLUG TYPE</Th>
          </Tr>
        </Thead>
        <Tbody>
          {vehiclesList && vehiclesList.map((vehicle: Vehicle , i: number) => {
            return (
              <Tr key={i}>
                <Td>{vehicle.brand}</Td>
                <Td>{vehicle.model}</Td>
                <Td>{vehicle.year}</Td>
                <Td>{getIcon(vehicle.plugType)}</Td>
              </Tr>)
          })}
        </Tbody>
      </Table>
      </>
      )}
    </Box>
  );
}
